

<script>

	export let data;


	import * as d3 from "d3"
    const comma = d3.format(',');


	//=====================
	//  moment
	//=====================
	import moment from 'moment';
	moment.locale('ms');
	moment.updateLocale('ms',{
	  months : ["Januari", "Februari", "Mac", "April", "Mei", "Jun", "Julai","Ogos", "September", "Oktober", "November", "Disember"],
	  monthsShort : ["Jan", "Feb", "Mac", "Apr", "Mei", "Jun", "Jul", "Ogo", "Sep", "Okt", "Nov", "Dis" ],
	  meridiem: function(hour, minute, isLowerCase)	{ return hour < 12 ? 'pagi' : hour==12 ? 'tengahari' : hour < 20 ? 'petang' : 'malam';},
	  weekdays : ["Ahad", "Isnin", "Selasa", "Rabu", "Khamis", "Jumaat", "Sabtu"]
	});



	//=====================
	//  randomCat
	//=====================
	// import { randomCats } from "$lib/randomCats.js"
	// $: randomCat = d3.shuffle(randomCats)[0];



	//====================================================================================
	//
	//====================================================================================

    import { onMount } from "svelte";
    import { browser } from '$app/environment';

    let brsize = 'sm';
	let innerwidth;
	let innerheight;


	onMount(() => {
		if(browser) {

            brsize = currentBreakpoint();

            // update brsize on resize
            window.addEventListener('resize', () => {
                brsize = currentBreakpoint();
            });


		}
	});




	//====================================================================================
	//
	//====================================================================================


    import { Breakpoint } from "carbon-components-svelte";
    let size='sm';
    let events = [];



    // get tailwind breakpoints
    let breakpoints = {
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        '2xl': 1536
    };

    // get current breakpoint
    const currentBreakpoint = () => {
        let bp = 'sm';
        for (const [key, value] of Object.entries(breakpoints)) {
            if (innerwidth >= value) {
                bp = key;
            }
        }
        return bp;
    };

    $: brsize = currentBreakpoint();


</script>



<svelte:window  bind:innerWidth={innerwidth} bind:innerHeight={innerheight} />


<Breakpoint bind:size on:change={(e) => (events = [...events, e.detail])} />

<footer class="
            no-print
            py-4
            text-xs
            bg-[#427FED]
            text-white
        ">

	<div class="text-center">



		<div style="display:flex; justify-content: center; align-items: center; margin:12px;">
			<div style="flex: 1 1 auto; text-align:center; max-width:600px; font-size:16px; text-xs">


                <!-- <div class="mb-8">
                    <div class="py-2">
                        <a class="text-white underline text-lg hover:opacity-80 mb-2" href="https://www.buymeacoffee.com/manamurah"  target="_blank">
                            Jom belanja kami kopi!
                        </a>
                    </div>
                    <div class="py-2 flex justify-center items-center">
                        <a class="hover:opacity-80" href="https://www.buymeacoffee.com/manamurah" target="_blank">
                            <img src="/buymeacoffee/bmc-brand-logo.svg" alt="Buy Me A Coffee" style="width: 195px; height: 40px; ">
                        </a>
                    </div>
                    <div class="text-xs">
                        Sumbangan anda membantu kami menyediakan maklumat harga terkini untuk menjimatkan perbelanjaan barang keperluan anda.
                    </div>

                </div>
 -->


				<div class="text-xs">

                    {#if data.maxDate}
                        <div class="py-1">
                            Tarikh Kemaskini:
                            <span style="white-space:nowrap;">{moment(data.maxDate).format('dddd D MMMM YYYY')}</span>
                        </div>
                    {/if}

					<div class="py-1">
						Sumber data :
							<a role="button" class="text-white visited:text-slate-300" href="https://pricecatcher.kpdn.gov.my/" target="_blank" rel="noopener noreferrer">PriceCatcher KPDN,</a>
							<a role="button" class="text-white visited:text-slate-300" href="https://open.dosm.gov.my/" target="_blank" rel="noopener noreferrer">Open DOSM</a>
					</div>

					<div class="m-4 text-xs">
						Maklumat harga adalah semasa pemantauan dibuat dan untuk tujuan sebagai rujukan sahaja.
						Pihak ManaMurah.com, DOSM, Portal PriceCatcher dan KPDN tidak bertanggungjawab
						atas sebarang kesilapan, kekurangan, ketidaktepatan,
                        atau dari sebarang implikasi dari maklumat yang dipaparkan.
					</div>


				</div>


                <div class="no-print">



                    <!-- <p class="my-2 font-thin text-xs"><em>ManaMurah.com is not affiliated with KPDN or DOSM.</em></p> -->

                    <div class="my-4 text-xs">

                        <a role="button" class=" text-white visited:text-slate-300" href="/my/about/">About Us</a> &middot;
                        <a role="button" class=" text-white visited:text-slate-300" href="/sitemap/">Sitemap</a> &middot;

                        <!--
                        <a role="button" class="bp4-button bp4-intent-primary" href="/my/terms/">Terms & Conditions</a>
                        <a role="button" class="bp4-button bp4-intent-primary" href="/my/disclaimer/">Disclaimer</a>
                        <a role="button" class="bp4-button bp4-intent-primary" href="/my/pdpa/">PDPA</a>
                        <a role="button" class="bp4-button bp4-intent-primary" href="/my/gdpr/">GDPR</a>
                        <a role="button" class="bp4-button bp4-intent-primary" href="/my/cpra/">CPRA</a>
                            <a role="button" class="bp4-button bp4-intent-primary" href="/my/refund/">Refund Policy</a>

                        -->
                        <a role="button" class=" text-white visited:text-slate-300" href="/my/contact-us/">Contact us</a>

                    </div>



                    <div class="my-4 text-xs">
                        &copy; 2024
                        ManaMurah.com<br>
                        <div class="text-xs mt-1">
                            All Rights Reserved.
                        </div>
                    </div>


                    <!-- <div class="text-xs m-4 color-slate-500 opacity-20">
                        {size} {innerwidth}x{innerheight} {brsize}
                    </div> -->


                </div>

                <div class="flex justify-center">
                    <div class="gcse-search text-black" style="color:black"></div>
                </div>

			</div>
		</div>


	</div>






    {#if data.pageview}
        <div class="text-center text-white">
            Page view:
            <span style="white-space:nowrap;">{ comma( data.pageview ) }</span>
        </div>
    {/if}


</footer>

